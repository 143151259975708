<template>
  <div>
    <PageTitle
      :title="candidateId ? candidateFullName : $t('candidate_details.title')"
      icon="mdi-account"
      back-button
    ></PageTitle>
    <v-row>
      <v-col cols="12" lg="7">
        <PersonalData :candidate="candidate" @saved="saved" />
      </v-col>
      <v-col cols="12" lg="5">
        <applications-list-simplified
          :applications="applications"
          data-cy="candidateApplications"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'
import PersonalData from '@/components/candidates/PersonalData.vue'
import PageTitle from '@/components/layout/PageTitle.vue'
import ApplicationsListSimplified from "@/components/jobApplications/ApplicationsListSimplified.vue";

export default {
  name: 'CandidateDetails',
  components: {
    ApplicationsListSimplified,
    PageTitle,
    PersonalData
  },
  computed: {
    ...mapState('candidates', ['candidate']),
    ...mapState('jobApplications', ['jobApplications']),
    candidateId() {
      return this.$route.params.id
    },
    candidateFullName() {
      return this.candidate
        ? `${this.candidate.first_name} ${this.candidate.last_name}`
        : '...'
    },
    applications() {
      return this.jobApplications.items
    }
  },
  watch: {
    '$route.params.id': {
      handler(candidateId) {
        if (candidateId) {
          this.$store.dispatch('candidates/getCandidate', candidateId)
          this.getJobApplications({
            'candidate': candidateId,
            'page': '-1' // All non paginated
          })
        } else this.$store.dispatch('candidates/unselectCandidate')
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapActions('jobApplications', ['getJobApplications']),
    saved(candidateId) {
      const newPath = `/candidates/${candidateId}/details`
      if (this.$router.history.current.path !== newPath) {
        this.$router.replace(newPath)
      }
    }
  }
}
</script>
