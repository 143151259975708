<template>
  <v-card class="pa-8">
    <v-row no-gutters>
      <v-col>
        <ComponentTitleWithDataLoader
          :title="title"
        ></ComponentTitleWithDataLoader>
      </v-col>
    </v-row>
    <div v-if="!dataIsLoading">
      <v-data-table
        :headers="headers"
        :items="applications"
        :sort-by="['status_text']"
        multi-sort
        :footer-props="{
          'items-per-page-text': $t('listing.job_applications.pagination')
        }"
        :item-class="rowClass"
        @click:row="clickRow"
      >
        <template v-slot:item.created_at="{ value }">
          {{ formatDate(value) }}
        </template>
        <template v-slot:item.salary_claim="{ value }">
          {{ value }}.-
        </template>
        <template v-slot:item.rate="{ value }">
          {{ value }}%
        </template>
        <template v-slot:item.status_text="{ item, value }">
          <div class="nowrap text-no-wrap">
            {{ value }}
            <v-icon v-if="item.status[0] === 'ARCHIVED'" aria-hidden="true">
              mdi-archive
            </v-icon>
          </div>
        </template>
        <template v-slot:item.assigned_user="{ value }">
          {{ value.first_name }} {{ value.last_name }}
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>
<script>
import { formatDate } from '@/helpers/dates'
import { mapGetters } from 'vuex'
import ComponentTitleWithDataLoader from '@/components/layout/ComponentTitleWithDataLoader.vue'
import RouterMixin from '@/mixins/routerMixin'

export default {
  name: 'ApplicationsListSimplified',
  components: { ComponentTitleWithDataLoader },
  mixins: [RouterMixin],
  props: {
    applications: {
      type: Array,
      require: true,
      default: null
    }
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: this.$t('listing.job_applications.headers.created_at'),
          value: 'created_at',
          align: 'start'
        },
        {
          text: this.$t('listing.job_applications.headers.rate'),
          value: 'rate'
        },
        {
          text: this.$t('global.salary'),
          value: 'salary_claim'
        },
        {
          text: this.$t('global.agent'),
          value: 'agent_name'
        },
        {
          text: this.$t('listing.job_applications.headers.status'),
          value: 'status_text'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('page', ['dataIsLoading']),
    title() {
      const count = this.applications.length
      const text = this.$t('listing.job_applications.registered')[(count > 1) ? 'plural' : 'singular']
      return `${count} ${text}`
    }
  },
  methods: {
    showDetails(applicationId) {
      this.routerPush(`/jobApplications/${applicationId}/details`)
    },
    formatDate,
    clickRow(item) {
      this.showDetails(item.id)
    },
    rowClass() {
      return "clickable-row"
    }
  }
}
</script>
<style>
  .clickable-row {
    cursor: pointer;
  }
</style>
